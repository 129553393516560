
import Vue from "vue";
import { initializeApp } from "firebase/app";
import * as firebaseui from "firebaseui";
import * as firebaseAuth from "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import "@/assets/styles/customize-firebase.css";
import { mapMutations } from "vuex";
import { IEvolveLABUser } from "@/modules/FirebaseHelpers";
import { browserSessionPersistence, setPersistence } from "firebase/auth";

export default Vue.extend({
  name: "LoginControl",
  components: {},
  data: function () {
    return {
      ui: (null as unknown) as firebaseui.auth.AuthUI,
      user: (null as unknown) as firebaseAuth.User,
      firebaseConfig: {
        apiKey: "AIzaSyCEOJdoj53z_tB48jnBiymY7kTlp8LTd8g",
        authDomain: "helix-333922.firebaseapp.com",
        projectId: "helix-333922",
        storageBucket: "helix-333922.appspot.com",
        messagingSenderId: "903731217666",
        appId: "1:903731217666:web:d173c709eeca7717bc2e1b",
        measurementId: "G-G2921Z96GY",
      },
    };
  },
  mounted: async function () {
    this.$nextTick(async () => {
      // @ts-ignore
      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: this.signInSuccessful,
        },
        signInOptions: [
          {
            provider: firebaseAuth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: false,
          },
        ],
      };
      // Initialize Firebase
      if (firebaseui.auth.AuthUI.getInstance()) {
        this.ui = firebaseui.auth.AuthUI.getInstance()!;
        // @ts-ignore
        this.ui.start("#firebase-ui-container", uiConfig);
      } else {
        const app = initializeApp(this.firebaseConfig);
        const auth = firebaseAuth.getAuth(app);
        this.SetCurrentAuthProvider(auth);
        await setPersistence(auth, browserSessionPersistence)
          .then(() => {
            if (auth.currentUser) {
              this.signInSuccessful({ user: auth.currentUser });
              return;
            }
            this.ui = new firebaseui.auth.AuthUI(auth);
            // @ts-ignore
            this.ui.start("#firebase-ui-container", uiConfig);
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.warn(errorCode, errorMessage);
          });
      }
    });
  },
  methods: {
    ...mapMutations(["SetCurrentUser", "SetCurrentAuthProvider"]),
    signInSuccessful: async function (authResult: any) {
      this.user = authResult.user;
      if (!this.user) {
        return;
      }

      this.user = this.user as firebaseAuth.User;
      this.SetCurrentUser({
        Email: this.user.email,
        Token: (this.user as any).accessToken ? (this.user as any).accessToken : "",
      } as IEvolveLABUser);

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
      });
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      const redirectTo = (params as any).redirectTo;
      const success_url = (params as any).success_url;
      const cancel_url = (params as any).cancel_url; 
      const price_id = (params as any).price_id; 
      console.log(`Route`, this.$route.query);
      console.log(`Custom Params`, redirectTo);

      if (!redirectTo) {
        this.$nextTick(() => {
          this.$router.push("/");
        });
        return;
      }

      // url of the form $'{baseUrl}/?redirectTo=checkout&success_url=https://evolvelab.io&cancel_url=https://evolvelab.io&price_id=price_1Kj7muHR7dLaxEUhndT4hmkJ'
      this.createCheckoutSession(this.user.email!, success_url, cancel_url, price_id);
    },
    createCheckoutSession: function (
      customer_email: string,
      success_url: string,
      cancel_url: string,
      price_id: string
    ) {
      const baseUrl = "https://evolvelab-licensing.uc.r.appspot.com";
      // const baseUrl = 'http://localhost:80';

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        customer_email: customer_email,
        success_url: success_url,
        cancel_url: cancel_url,
        price_id: price_id,
      });

      fetch(`${baseUrl}/checkout`, {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      })
        .then(response => response.json())
        .then((result) => {
          console.log(result);
          location.href = result.url;
        })
        .catch((error) => console.log("error", error));
    },
  },
});
