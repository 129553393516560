
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "ElHeader",
  computed: {
    ...mapGetters(["GetCurrentAuthProvider", "IsLoggedIn"]),
  },
  methods: {
    logout: function() {
      this.GetCurrentAuthProvider.signOut();
      location.reload();
    }
  }
});
