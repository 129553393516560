
import Vue from "vue";
import { mapState } from "vuex";
import ElHeader from "./components/ElHeader.vue";

export default Vue.extend({
  name: "App",
  components: {
    ElHeader,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState(["IsLoggedIn"]),
  },
  created: function () {
    if (!this.IsLoggedIn) {
      this.$router.push("/login");
    }
  },
});
