import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginControl from '@/components/login-control/LoginControl.vue';
import ErrorPage from '@/components/login-control/ErrorPage.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Product Teams',
    component: () => import(/* webpackChunkName: "TheTeamsContainer" */ '@/components/TheTeamsContainer.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginControl
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage
  }
]

const router = new VueRouter({
  routes
})

export default router
