import axios, { Axios } from "axios";

const BASE_EVOLVELAB_URL: string = 'https://evolvelab-licensing.uc.r.appspot.com/';

//#region GET methods
/**
 * JSON object that results from the GetAllProductTeams endpoint.
 * 
 */
export type ProductTeam = {
    manager_email: string,
    members: string[],
    number_of_seats: number,
    product_id: string,
    product_name: "Glyph" | "Helix" | "Veras" | "Morphis", // we can change this to a generic string in future if need be
}
export async function GetAllProductTeams(managerEmail: string, authToken: string,): Promise<ProductTeam[]> {
    const url = `${BASE_EVOLVELAB_URL}teams/${managerEmail}`;

    const myHeaders = new Headers();
    myHeaders.append("Access-Token", authToken);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    const response = await fetch(url, requestOptions)
    .then(response => {
        if (response.status === 400) {
            throw response.text();
        }
        return response.text();
    })
    .catch(error => {
        console.warn('api error', error);
        throw error;
    });

    if (response) {
        const parsed = JSON.parse(response);
        return parsed ? parsed : [];
    } else {
        return [];
    }
}

//#endregion GET methods

//#region PUT/POST methods

/**
 * Tells the API that a Product Team was altered by sending it the updated Team.
 * Returns True if successful. False on failure
 * We'll want to make these calls in Vuex b/c changes will immediately be pushed to the overall state
 * @param team
 */
export async function PutTeamUpdate(
    team: ProductTeam, 
    managerEmail: string, 
    authToken: string,
): Promise<boolean> {
    const url = `${BASE_EVOLVELAB_URL}teams/${managerEmail}/${team.product_id}`;

    const myHeaders = new Headers();
    myHeaders.append("Access-Token", authToken);
    myHeaders.append("Content-Type", "application/json");

    const bodyString = JSON.stringify({
        members: team.members
    })

    const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: bodyString,
    };

    const response = await fetch(url, requestOptions)
    .catch(error => {
        console.warn('api error', error);
        throw error;
    });

    return response ? response.ok : false;
}

//#endregion PUT/POST methods

//#region dev constants

/** Test Helix Team for dev purposes */
const TestHelixTeam: ProductTeam = {
    manager_email: "mark@evolvelab.io",
    members: [
        "chris@evolvelab.io",
        "ben@evolvelab.io"
    ],
    number_of_seats: 4,
    product_id: "4345345fafd",
    product_name: "Helix"
}

const TestGlyphTeam: ProductTeam = {
    manager_email: "mark@evolvelab.io",
    members: [
        "clara@evolvelab.io",
        "bill@evolvelab.io"
    ],
    number_of_seats: 6,
    product_id: "434534adfads5fafd",
    product_name: "Glyph"
}

//#endregion dev constants