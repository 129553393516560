import Vue from 'vue';
import App from './App.vue';

// material icons
import 'material-icons/iconfont/outlined.css';
import 'material-icons/iconfont/round.css';
// get tailwind
import "@/assets/styles/styles.css";
import "@/assets/styles/custom-variables.css";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/reset.css";
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
