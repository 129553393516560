
import Vue from "vue";
export default Vue.extend({
  name: "ErrorPage",
  components: {},
  data: function () {
    return {};
  },
  methods: {},
});
