import Vue from 'vue'
import Vuex from 'vuex'
import { GetAllProductTeams, ProductTeam, PutTeamUpdate } from '@/modules/API'
import { IEvolveLABUser } from '@/modules/FirebaseHelpers'

import * as firebaseAuth from "firebase/auth";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    CurrentUser: null as IEvolveLABUser | null,
    CurrentProductTeams: [] as ProductTeam[],
    CurrentAuthProvider: null as firebaseAuth.Auth | null,
    LoadingProductTeams: false,
    ErrorAlertMessage: "",
  },
  getters: {
    IsLoggedIn: function(state): boolean {
      return state.CurrentUser != null && state.CurrentUser.Token !== "";
    },
    GetCurrentAuthProvider: function(state) {
      return state.CurrentAuthProvider;
    }
  },
  mutations: {
    SetErrorMessage: function(state, message: string) {
      state.ErrorAlertMessage = message;
    },
    SetLoadingProductTeamsState(state, bool: boolean) {
      state.LoadingProductTeams = bool;
    },
    SetCurrentUser: function(state, user: IEvolveLABUser) {
      state.CurrentUser = user;
    },
    /** Sets state.CurrentProductTeams */
    SetCurrentProductTeams: function(state, teams: ProductTeam[]) {
      state.CurrentProductTeams = teams;
    },
    SetCurrentAuthProvider: function(state, auth: firebaseAuth.Auth) {
      state.CurrentAuthProvider = auth;
    },
    /** Updates a product team with the new, given team */
    UpdateProductTeam: function(state, team: ProductTeam) {
      const teamUpdateIndex = state.CurrentProductTeams.findIndex(t => t.product_id === team.product_id);
      if (teamUpdateIndex !== -1) {
        state.CurrentProductTeams.splice(teamUpdateIndex, 1, team);
      }
    }
  },
  actions: {
    GetUsersProductTeams: function({state, commit}) {
      if (!state.CurrentUser) {
        console.warn("no users set");
        return;
      }

      commit("SetErrorMessage", "");
      commit("SetLoadingProductTeamsState", true);

      GetAllProductTeams(state.CurrentUser.Email, state.CurrentUser.Token).then(teams => {
        commit("SetCurrentProductTeams", teams);
        commit("SetLoadingProductTeamsState", false);
      }).catch(error => {
        error.then((e: string) => {
          console.warn(e);
          commit("SetLoadingProductTeamsState", false);
          commit("SetErrorMessage", e);
        });
      });
    },
    /**
     * Sends an API request to Put an Updated Product Team.
     * Then commits the new team to the State
     * @param param0 
     * @param team 
     */
    CreatePutProductTeam: function({ state, commit }, team: ProductTeam) {
      if (!state.CurrentUser) {
        console.warn("no users set");
        return;
      }

      commit("SetErrorMessage", "");
      
      PutTeamUpdate(team, state.CurrentUser.Email, state.CurrentUser.Token).then(success => {
        if (success) {
          commit("UpdateProductTeam", team);
        }
      }).catch(error => {
        console.warn(error);
        commit("SetErrorMessage", "Unable to Update Team");
      });
    }
  },
  modules: {
  }
})
